import http from "../../utils/http"

/**
 * 商品详情砍价列表
 * @param {object} params
 */
export function goodsBargainList(params) {
    return http({
        url: "mling/api/goodsbargain/getGoodsBargainList",
        data: params
    })
}
/**
 * 商品详情修改砍价
 * @param {object} params
 */
export function goodsChangeBargain(params) {
    return http({
        url: "mling/api/goodsbargain/getGoodsBargainInfo",
        data: params
    })
}
/**
 * 商品详情砍价
 * @param {object} params
 */
export function goodsDetailBargain(params) {
    return http({
        url: "mling/api/goodsbargain/addGoodsBargain",
        data: params,
		forceLogin: true
    })
}
/**
 * 商品详情评论列表
 * @param {object} params
 */
export function goodsCommentList(params) {
    return http({
        url: "mling/api/goodscomment/getGoodsCommentList",
        data: params
    })
}
/**
 * 商品详情评论
 * @param {object} params
 */
export function getGoodsComment(params) {
    return http({
        url: "mling/api/goodscomment/addGoodsComment",
        data: params
    })
}
/**
 * 商品详情评论更多
 * @param {object} params
 */
export function getMoreGoodsComment(params) {
    return http({
        url: "mling/api/goodscomment/getGoodsCommentMoreList",
        data: params
    })
}
/**
 * 商品详情评论详情
 * @param {object} params
 */
export function getGoodsCommentInfo(params) {
    return http({
        url: "mling/api/goodscomment/getGoodsCommentInfo",
        data: params
    })
}
/**
 * 商品详情评论删除
 * @param {object} params
 */
export function delGoodsComment(params) {
    return http({
        url: "mling/api/goodscomment/delGoodsComment",
        data: params
    })
}
/**
 * 商品详情报价列表
 * @param {object} params
 */
export function getGoodsTender(params) {
    return http({
        url: "mling/api/goodstender/getGoodsTenderList",
        data: params
    })
}
/**
 * 商品详情报价
 * @param {object} params
 */
export function getGoodsOffer(params) {
    return http({
        url: "mling/api/goodstender/addGoodsTender",
        data: params
    })
}
/**
 * 商品详情报价编辑
 * @param {object} params
 */
export function updateGoodsOffer(params) {
    return http({
        url: "mling/api/goodstender/editGoodsTender",
        data: params
    })
}
/**
 * 商品详情报价删除
 * @param {object} params
 */
export function delGoodsOffer(params) {
    return http({
        url: "mling/api/goodstender/deleteGoodsTender",
        data: params
    })
}