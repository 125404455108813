<template>
    <div>
        <!-- 报价弹框 -->
		<el-dialog :show-close="false" :visible.sync="dialogOfferVisible" width="500px" custom-class="dialog-offer">
               <div class="area_input">
				   <el-input type="textarea" v-model="offerData.offercontent" resize="none" placeholder="请输入描述"></el-input>
				   <div class="imgs" >
						<div class="img_item" v-for="(item,index) in offerData.offerImgList" :key="index">
							<el-image :src="$img(item)"  :preview-src-list="offerData.offerImgBigList" fit="cover"></el-image>
							<div @click="deleteImg(index)" class="el-icon-error"></div>
						</div>
				   </div>
			   </div>
			   <div class="action_botton">
				   <el-upload ref="upload" :data="{token:token}"  :show-file-list="false" :action="uploadActionUrl" list-type="picture-card" :on-success=" (file, fileList) => { return handleSuccess(file, fileList); } "  :on-exceed="handleExceed" :limit="5">
				   	<i class="iconfont1 icon-tianjiatupian"></i>
				   </el-upload>
				   <input class="price_input" placeholder="请输入价格" v-model="offerData.offerPrice" />
				   <el-button type="primary" @click="handleCancel()">确定</el-button>
			   </div>
		</el-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import Config from '@/utils/config';
import {getGoodsOffer,updateGoodsOffer } from '@/api/goods/detail'
    export default {
        props:{
            id:{
                type:Number,
                default:0
            }
        },
        data() {
            return {
                dialogOfferVisible: false,
                status:0,
                offerData:{
                    tender_id:'',
                    offercode:'',
                    offerPrice: '',
                    offercontent:'',
                    offerImgList:[],
                    offerImgBigList:[],
                },
                uploadActionUrl: Config.baseUrl + 'mling/api/membergoods/uploadimagetoalbum', //表情图标

            }
        },
        computed: {
            ...mapGetters(["token"])
        },
        methods:{
            deleteImg(index){
                this.offerData.offerImgList.splice(index,1)
                this.offerData.offerImgBigList.splice(index,1)
            },
            handleExceed(file, fileList) {
                // 图片数量大于6
                this.$message.warning('上传图片最大数量为5张');
            },
            handleSuccess(file, fileList) {
                this.offerData.offerImgList = this.offerData.offerImgList.concat(file.data.pic_path);
                this.offerData.offerImgBigList = this.offerData.offerImgBigList.concat(this.$img(file.data.pic_path));
            },
            // 投标
            handleOffer(){
                if(!this.offerData.offerPrice){
                    this.$message.error('请输入价格');
                    return;
                }
                if(this.offerData.offerPrice < 0){
                    this.$message.error('投标价格必须大于0')
                    return
                }
                if(isNaN(this.offerData.offerPrice)){
                    this.$message.error('请输入合规的价格')
                    return
                }
                if(!this.offerData.offerImgList.length){
                    this.$message.error('请上传照片');
                    return;
                }
                getGoodsOffer({
                    goods_id: this.id,
                    price: this.offerData.offerPrice,
                    content: this.offerData.offercontent,
                    images: this.offerData.offerImgList.toString()
                }).then(res => {
                    if (res.code >= 0 && res.data) {
                        this.$message({
                            type:'success',
                            message: '投标成功',
                            duration:700
                        });
                        this.dialogOfferVisible = false
                        this.$emit('hendleData')
                        this.offerData.offercode = '',
                        this.offerData.offerPrice="",
                        this.offerData.offercontent="",
                        this.offerData.offerImgList=[],
                        this.offerData.offerImgBigList=[]
                    }
                }) 
            },
            // 编辑
            hanlleEdit(){
                if(!this.offerData.offerPrice){
                    this.$message.error('请输入价格');
                    return;
                }
                if(this.offerData.offerPrice < 0){
                    this.$message.error('请输入大于0的砍价价格')
                    return
                }
                if(isNaN(this.offerData.offerPrice)){
                    this.$message.error('请输入合规的价格')
                    return
                }
                if(!this.offerData.offerImgList.length){
                    this.$message.error('请上传照片');
                    return;
                }
                updateGoodsOffer({
                    tender_id: this.offerData.tender_id,
                    goods_id: this.id,
                    price: this.offerData.offerPrice,
                    content: this.offerData.offercontent,
                    // goods_code: this.offerData.offercode,
                    images: this.offerData.offerImgList.toString()
                }).then(res => {
                    if (res.code == 0 && res.data) {
                        this.$message({
                            type:'success',
                            message: '修改投标成功',
                            duration:700
                        });
                        this.status = 0
                        this.dialogOfferVisible = false
                        this.$emit('hendleData')
                        this.offerData.offercode = '',
                        this.offerData.offerPrice="",
                        this.offerData.offercontent="",
                        this.offerData.offerImgList=[],
                        this.offerData.offerImgBigList=[]
                    }
                }) 
            },
            handleCancel() {
                if(this.status == 1){
                    this.hanlleEdit()
                }else{
                    this.handleOffer()
                }
            },
        }
    }
</script>

<style lang="scss">
	.dialog-offer{
		.el-dialog__header{
			padding: 0;
		}
		.el-dialog__body{
			padding: 14px;
			.action_botton{
				margin-top: 18px;
				display: flex;
				align-items: center;
				button{
					height: 37px;
					padding: 0 53px;
					background-color: #F7F8FB;
					color: #30373D;
					font-weight: 600;
					font-family: "PingFang SC";
					border-color: #F7F8FB;
					margin-left: 14px;
					border-radius: 24px;
				}
				.price_input{
					border-radius: 24px;
					background-color: #F7F8FB;
					outline: none;
					border: none;
					font-family: "PingFang SC";
					color: #30373D;
					font-size: 14px;
					line-height: 23px;
					text-align: center;
					padding: 7px 0;
					flex: 1;
					&::placeholder{
						color: rgba(48, 55, 61, 0.50);
						font-family: "PingFang SC";
					}
				}
				.el-upload--picture-card{
					width: 22px;
					height: 22px;
					line-height:0;
					display: flex;
					align-items: center;
					margin-right: 58px;
					background-color: transparent;
					border: none;
					.icon-tianjiatupian{
						color: rgba(48, 55, 61, 0.80);
						font-size: 22px!important;
					}
				}
			}
			.area_input{
				background-color: #F7F8FB;
				border-radius: 5px;
				padding: 14px;
				.el-textarea{
					padding: 0;
					min-height: 80px;
					.el-textarea__inner{
						background-color: transparent;
						border: none;
						padding: 0;
						&::placeholder{
							color: rgba(48, 55, 61, 0.50);
							font-family: "PingFang SC";
						}
					}
				}
				.imgs{
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					.img_item{
						width: 50px;
						height: 50px;
						position: relative;
						margin-right: 15px;
						margin-top: 10px;
						.el-image{
							width: 50px;
							height: 50px;
							img{
								cursor: pointer;
								width: 100%;
								height: 100%;
								display: block;
								border-radius: 4px;
							}
						}
						.el-icon-error{
							cursor: pointer;
							position: absolute;
							right: 0;
							top: 0;
							z-index: 10;
							transform: translate(50%,-50%);
						}
					}
				}
			}
		}
	}
</style>