import { mapGetters } from "vuex"
import { goodsBargainList,goodsChangeBargain,goodsDetailBargain,goodsCommentList,getGoodsComment,getMoreGoodsComment,delGoodsComment,getGoodsCommentInfo,getGoodsTender,delGoodsOffer, getGoodsOffer} from "@/api/goods/detail"
import { goodsDetail, isCollect, isCart, addCollect, deleteCollect, addCart, deleteCart, addBrowse, deleteBrowse, selectGoodsTender, orderCreate } from '@/api/resource.js'
import Config from '@/utils/config';
import offer from './components/offer.vue';
export default {
    components:{
        offer 
    },
    data: () => {
        return {
			toUid:'',
            current_mode: 1,
            current_category: 0,
			detailStatus:'',
            goodsBargainList:[],
            bargainPrice:'',
            bargainCurrentPage: 1,
			bargainPageSize: 10,
            bargainTotal:0,
            skuId:'',
            loading: true,
            replyStatus: false,
            comment:'',
            commentList: [],
            commentCurrentPage: 1,
            commentPageSize: 2,
            commentTotal:0,
            uploadActionUrl: Config.baseUrl + 'mling/api/membergoods/uploadimagetoalbum', //表情图标
            imgList:[],
            imgBigList:[],
            dialogReplyVisible:false,
            reply:{},
            replyChild:{},
            replyComment:'',
            replyImgList:[],
            replyImgBigList:[],
            replyindex:0,
            comment_ids:[],
            offerCurrentPage:1,
            offerPageSize:2,
            offerList:[],
            offerTotal:0,
			goods_info:{},
			goods_show:1,
			info_loading:true,
			is_collect:0,
			is_cart:0,
			fee_data:[],
			fee_total_price:0,
			offerData:{
			    tender_id:'',
			    offercode:'',
			    offerPrice: '',
			    offercontent:'',
			    offerImgList:[],
			    offerImgBigList:[],
			},
			content_sort:[],
			trade_progress:'',
			flag:false,
        }
    },
    created() {
        this.skuId = Number(this.$route.params.pathMatch);
		if(this.$route.query.status){
			this.detailStatus = this.$route.query.status;
		}
        // this.getUserInfo();
		if(this.$route.path.split('-')[0]=='/demand'){
			this.current_mode = 2;
		}else{
			this.getBargainPrice();
		}
        this.bargainList();
        this.getCommnetList();
        this.getOfferList();
		this.getGoodsDetail();
		if(this.token){
			this.getIsCollect();
			this.getIsCart();
			this.toAddBrowse();
		}
    },
    computed: {
        ...mapGetters(["token", "defaultHeadImage", "defaultShopImage", "defaultGoodsImage", "addonIsExit", "locationRegion","member"])
    },
    watch: {
        $route: function (curr) {
            this.skuId = Number(curr.params.pathMatch);
        },
    },
	mounted(){
		if(localStorage.getItem('isKefuDetail') && this.token){
			this.toUid = 'member_' + localStorage.getItem('isKefuDetail');
			localStorage.removeItem('isKefuDetail');
			this.$refs.servicerMessage.show();
		}
	},
    methods: {
		scrollToCenter(ID){
			this.$nextTick(()=>{
				window.scrollTo({
					'top': document.getElementById(ID).offsetTop - 30,
					// 'top': document.getElementById(ID).offsetTop - ( window.innerHeight / 2 ) + ( document.getElementById(ID).clientHeight / 2 ),
					'behavior': 'smooth'
				})
			})
		},
		toOfferGoods(){
			this.detailStatus = 'tender';
			this.scrollToCenter('detail_content');
		},
		toBargain(){
			this.detailStatus = 'bargain';
			this.scrollToCenter('detail_content');
		},
		toMessage(){
			this.detailStatus = 'comment';
			this.scrollToCenter('detail_content');
		},
		dealProductlist(mode){
			var arr = [] ;
			if(this.goods_info.trademark_info.productlist){
				this.goods_info.trademark_info.productlist.forEach(item=>{
					if(mode=='name'){
						arr.push(item.name)
					}else{
						arr.push(item.classid)
					}
				})
			}
			return arr.join(';');
		},
		selectGoodsTender(id){
			if(this.flag) return;
			this.flag = true;
			selectGoodsTender({
				tender_id:id
			})
			.then(res=>{
				if(res.code>=0){
					this.$message.success('选择成功');
					this.offerCurrentPage = 1;
					this.getOfferList();
				}else{
					this.$message.error(res.message);
					this.flag = false;
				}
			})
			.catch(err=>{
				this.$message.error(err.message);
				this.flag = false;
			})
		},
		addOffer(){
			if(!this.token){
				this.$message.warning('请先进行登录');
				setTimeout(()=>{
					if(this.current_mode==1){
						this.$router.push({path: '/login', query: {redirect: "/resource-"+this.skuId}});
					}else{
						this.$router.push({path: '/login', query: {redirect: "/demand-"+this.skuId}});
					}
				},800)
				return;
			}
			if(!this.offerData.offerPrice){
			    this.$message.error('请输入价格');
			    return;
			}
			if(this.offerData.offerPrice < 0){
			    this.$message.error('投标价格必须大于0')
			    return
			}
			if(isNaN(this.offerData.offerPrice)){
			    this.$message.error('请输入合规的价格')
			    return
			}
			if(!this.offerData.offerImgList.length){
			    this.$message.error('请上传照片');
			    return;
			}
			getGoodsOffer({
			    goods_id: this.skuId,
			    price: this.offerData.offerPrice,
			    content: this.offerData.offercontent,
			    images: this.offerData.offerImgList.toString()
			}).then(res => {
			    if (res.code >= 0 && res.data) {
			        this.$message({
			            type:'success',
			            message: '投标成功',
			            duration:700
			        });
			        this.handleOfferList();
			        this.offerData.offercode = '',
			        this.offerData.offerPrice="",
			        this.offerData.offercontent="",
			        this.offerData.offerImgList=[],
			        this.offerData.offerImgBigList=[]
			    }
			}) 
		},
		deleteOfferImg(index){
		    this.offerData.offerImgList.splice(index,1)
		    this.offerData.offerImgBigList.splice(index,1)
		},
		handleOfferExceed(file, fileList) {
		    // 图片数量大于6
		    this.$message.warning('上传图片最大数量为5张');
		},
		handleOfferSuccess(file, fileList) {
		    this.offerData.offerImgList = this.offerData.offerImgList.concat(file.data.pic_path);
		    this.offerData.offerImgBigList = this.offerData.offerImgBigList.concat(this.$img(file.data.pic_path));
		},
		toViewChat(id=''){
			if(!this.token){
				this.$message.warning('请先进行登录');
				if(id){
					localStorage.setItem("isKefuDetail",id);
				}else{
					localStorage.setItem("isKefuDetail",this.goods_info.member_info.member_id);
				}
				setTimeout(()=>{
					if(this.current_mode==1){
						this.$router.push({path: '/login', query: {redirect: "/resource-"+this.skuId}});
					}else{
						this.$router.push({path: '/login', query: {redirect: "/demand-"+this.skuId}});
					}
				},800)
				return;
			}
			if(id){
				this.toUid = 'member_'+id;
			}else{
				this.toUid = 'member_'+this.goods_info.member_info.member_id;
			}
			this.$refs.servicerMessage.show();
		},
		toMemberDetail(id){
			this.$router.pushToTab('/personal-'+id);
		},
		errorImg(mode,index){
			switch (mode){
				case 'goods':
					this.goods_info.goods_image[index] = this.defaultGoodsImage;
					break;
				case 'company':
					this.goods_info.extend_data.company_image[index] = this.defaultGoodsImage;
					break;
				case 'logo':
					this.goods_info.extend_data.logo_image[index] = this.defaultGoodsImage;
					break;
				default:
					break;
			}
			this.$forceUpdate();
		},
		toAddBrowse(){
			addBrowse({
				goods_id:this.skuId
			})
			.then(res=>{
				
			})
		},
		buyGoods(){
			if(!this.token){
				this.$message.error('请先进行登录');
				// var data = {
				// 	goods_id: this.skuId,
				// }
				// this.$store.dispatch("order/setOrderCreateData", data)
				setTimeout(()=>{
					this.$router.push({path: '/login', query: {redirect: "/resource-"+this.skuId}});
					// this.$router.push({path: '/login', query: {redirect: "/payment"}});
				},800)
				return;
			}
			// var data = {
			// 	goods_id: this.skuId,
			// }
			if(this.flag) return;
			this.flag = true;
			orderCreate({
				goods_id:this.skuId
			})
			.then(res=>{
				if(res.code>=0){
					this.$message({
						message:'下单成功',
						type:'success'
					});
					this.flag = false;
					setTimeout(()=>{
						// 跳到订单详情页面
						this.$router.push('/member/order_detail?order_id='+res.data.order_id+'&role=buyer')
					},800)
				}else{
					this.$message.error(res.message);
					this.flag = false;
				}
			})
			.catch(err=>{
				this.$message.error(err.message);
				this.flag = false;
			})
			// this.$store.dispatch("order/setOrderCreateData", data)
			// this.$router.push('/payment');
		},
		cartGoods(){
			if(!this.token){
				this.$message.error('请先进行登录');
				setTimeout(()=>{
					this.$router.push({path: '/login', query: {redirect: "/resource-"+this.skuId}});
				},800)
				return;
			}
			if(this.is_cart){
				deleteCart({
					goods_id:this.skuId
				})
				.then(res=>{
					if(res.code>=0){
						this.getIsCart();
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			}else{
				addCart({
					goods_id:this.skuId
				})
				.then(res=>{
					if(res.code>=0){
						this.getIsCart();
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			}
			
		},
		collectGoods(){
			if(!this.token){
				this.$message.error('请先进行登录');
				setTimeout(()=>{
					this.$router.push({path: '/login', query: {redirect: "/resource-"+this.skuId}});
				},800)
				return;
			}
			if(this.is_collect){
				deleteCollect({
					goods_id:this.skuId
				})
				.then(res=>{
					if(res.code>=0){
						this.getIsCollect();
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			}else{
				addCollect({
					goods_id:this.skuId
				})
				.then(res=>{
					if(res.code>=0){
						this.getIsCollect();
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			}
		},
		getIsCart(){
			isCart({
				goods_id:this.skuId
			})
			.then(res=>{
				if(res.code>=0){
					this.is_cart = res.data;
				}
			})
		},
		getIsCollect(){
			isCollect({
				goods_id:this.skuId
			})
			.then(res=>{
				if(res.code>=0){
					this.is_collect = res.data;
				}
			})
		},
		getGoodsDetail(){
			goodsDetail({
				goods_id:this.skuId
			})
			.then(res=>{
				if(res.code>=0 ){
					this.goods_info = res.data;
					if(this.goods_info.goods_attr_format) this.goods_info.goods_attr_parse = JSON.parse(this.goods_info.goods_attr_format);
					if(this.goods_info.extend_data){
						this.goods_info.extend_data = JSON.parse(this.goods_info.extend_data);
						if(this.goods_info.extend_data.company_attr_format)	this.goods_info.extend_data.company_attr_format = JSON.parse(this.goods_info.extend_data.company_attr_format);
						if(this.goods_info.extend_data.logo_attr_format) this.goods_info.extend_data.logo_attr_format = JSON.parse(this.goods_info.extend_data.logo_attr_format);
						if(this.goods_info.extend_data.company_image) this.goods_info.extend_data.company_image = this.goods_info.extend_data.company_image.split(',')
						if(this.goods_info.extend_data.logo_image) this.goods_info.extend_data.logo_image = this.goods_info.extend_data.logo_image.split(',')
					}
					if(this.goods_info.fee_data){
						this.goods_info.fee_data = JSON.parse(this.goods_info.fee_data);
						this.fee_total_price = 0;
						this.goods_info.fee_data.forEach(item=>{
							this.fee_total_price += Number(item.price);
						});
					}
					if(this.goods_info.content_sort){
						this.content_sort = this.goods_info.content_sort;
						if(!this.detailStatus) this.detailStatus = this.content_sort[0].id;
					}else{
						if(!this.detailStatus) this.detailStatus = 'comment';
					}
					if(this.goods_info.trade_progress) this.trade_progress = JSON.parse( this.goods_info.trade_progress );
					if(this.goods_info.goods_content) this.goods_info.goods_content = JSON.parse(this.goods_info.goods_content);
					if(this.goods_info.goods_image) this.goods_info.goods_image = this.goods_info.goods_image.split(',');
					this.info_loading = false;
				}else{
					this.$router.push('/');
				}
			})
		},
        chooseCategory(index) {
            this.current_category = index
            /**
             * 1)先让选中的元素滚到可视区域的最左边 scrollLeft
             * 2)接着向右移动容器一半的距离 containWidth / 2
             * 3)最后向左移动item一半的距离 offsetWidth / 2
             */
            let lastSpot = this.$refs.scrollBox.scrollLeft
            const nextSpace = 50 //每次移动距离
            let scrollItemTimer = setInterval(() => {
                this.$nextTick(() => {
                    let offsetWidth = this.$refs.scrollItem[this.current_category].offsetWidth //item
                    let scrollLeft = this.$refs.scrollItem[this.current_category].offsetLeft //选中的元素滚到可视区域的最左边
                    const containWidth = this.$refs.scrollBox.offsetWidth //容器的宽度
                    if (this.$refs.scrollItem[0].offsetLeft == 0) {
                        var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2 //最终要停留的点
                    } else {
						var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2 - this.$refs.scrollItem[0].offsetLeft //最终要停留的点
                    }
                    if (Math.abs(lastSpot - resultSpot) < nextSpace) {
                        clearInterval(scrollItemTimer)
                    }
                    if (resultSpot >= lastSpot) {
                        lastSpot = lastSpot + nextSpace
                    } else {
                        lastSpot = lastSpot - nextSpace
                    }
                    this.$refs.scrollBox.scrollTo(lastSpot, 0)
                })
            }, 15)
        },
        changeMode(mode) {
            this.current_mode = mode
        },
        //获取用户信息
		// getUserInfo() {
		// 	this.$store
		// 		.dispatch('member/member_detail', {
		// 			refresh: 1
		// 		})
		// 		.then(res => {
		//			this.member = res.data
		// 		})
		// 		.catch(err => {
		// 			this.$message.error(err.message);
		// 		});
		// },
        // 修改砍价
		getBargainPrice(){
			goodsChangeBargain({
			    goods_id: this.skuId,
			}).then(res => {
			    if (res.code == 0 && res.data) {
			        this.bargainPrice = res.data.price
			    }
			})
		},
        // 砍价
        goBargain(){
			if(!this.token){
				this.$message.warning('请先进行登录');
				setTimeout(()=>{
					if(this.current_mode==1){
						this.$router.push({path: '/login', query: {redirect: "/resource-"+this.skuId}});
					}else{
						this.$router.push({path: '/login', query: {redirect: "/demand-"+this.skuId}});
					}
				},800)
				return;
			}
           if(!this.bargainPrice){
               this.$message.error('请输入砍价价格')
               return
           }
           if(this.bargainPrice < 0){
               this.$message.error('请输入大于0的砍价价格')
               return
           }
           if(isNaN(this.bargainPrice)){
               this.$message.error('请输入合规的价格')
               return
           }
           goodsDetailBargain({
            goods_id: this.skuId,
            price:this.bargainPrice
            }).then(res => {
                if (res.code == 0) {
                    this.bargainCurrentPage = 1
                    this.bargainList()
                }else{
					this.$message.error(res.message);
				}
            })
        },
        // 切换tab
		handleTabs(data){
			this.detailStatus = data
			this.scrollToCenter('detail_content')
		},
        // 砍价列表
        bargainList(){
            goodsBargainList({
                goods_id: this.skuId,
                page: this.bargainCurrentPage,
                page_size: this.bargainPageSize
            }).then(res => {
                if (res.code == 0 && res.data) {
                    this.goodsBargainList = res.data.list;
                    this.bargainTotal = res.data.count;
                    this.loading = false;
                }
				this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },
        handlePageSizeChange(size) {
            this.bargainPageSize = size;
            this.bargainList();
        },
        handleCurrentPageChange(page) {
            this.bargainCurrentPage = page;
            this.bargainList();
        },
        // 评论列表
        getCommnetList() {
            goodsCommentList({
                goods_id: this.skuId,
                page: this.commentCurrentPage,
                page_size: this.commentPageSize
            }).then(res => {
                if (res.code == 0 && res.data) {
                    this.commentTotal = res.data.count;
                    this.commentList = res.data.list.map(item =>{
                        item.checkbox = false;
                        item.show = false;
                        item.child= [];
                        item.replyCurrentPage = 1;
                        item.replyPageSize = 3;
                        item.replyPageCount= 0;
						item.showReply = false;
                       if(item.images.length){
                            item.imagesList = item.images.map(el =>{
                                return  this.$img(el)
                            })
                        }
                        return item
                    });
                }
				this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },
        handleCommentPageSizeChange(size) {
            this.commentPageSize = size;
            this.comment_ids = []
			this.loading = true;
            this.getCommnetList();
        },
        handleCommentCurrentPageChange(page) {
            this.commentCurrentPage = page;
			this.loading = true;
            this.getCommnetList();
            this.comment_ids = []
        },
        deleteImg(index){
			if(this.replyStatus){
				this.replyImgList.splice(index,1)
				this.replyImgBigList.splice(index,1)
			}else{
				this.imgList.splice(index,1)
				this.imgBigList.splice(index,1)
			}
        },
		uploadBefore(file, fileList){
			if(!this.token){
				this.$message.error('请先去登录');
				setTimeout(()=>{
					if(this.current_mode==1){
						this.$router.push({path: '/login', query: {redirect: "/resource-"+this.skuId}});
					}else{
						this.$router.push({path: '/login', query: {redirect: "/demand-"+this.skuId}});
					}
				},800)
				return false;
			}
			if(this.replyStatus){
				if(this.replyImgList.length>=5){
					this.$message.warning('上传图片最大数量为5张');
					return false;
				}
			}else{
				if(this.imgList.length>=5){
					this.$message.warning('上传图片最大数量为5张');
					return false;
				}
			}
		},
        handleExceed(file, fileList) {
            // 图片数量大于6
            this.$message.warning('上传图片最大数量为5张');
        },
        handleSuccess(file, fileList) {
            if(!this.replyStatus){
                this.imgList = this.imgList.concat(file.data.pic_path);
                this.imgBigList = this.imgBigList.concat(this.$img(file.data.pic_path));
            }else{
                this.replyImgList = this.replyImgList.concat(file.data.pic_path);
                this.replyImgBigList = this.replyImgBigList.concat(this.$img(file.data.pic_path));
            }
        },
        // 评论
        sendComment(data = {}) {
			if(!this.token){
				this.$message.warning('请先进行登录');
				setTimeout(()=>{
					if(this.current_mode==1){
						this.$router.push({path: '/login', query: {redirect: "/resource-"+this.skuId}});
					}else{
						this.$router.push({path: '/login', query: {redirect: "/demand-"+this.skuId}});
					}
				},800)
				return;
			}
            if(!this.replyStatus){
                if(!this.comment || !this.imgList){
                    this.$message.error('请输入评论内容')
                    return
                }
                getGoodsComment({
                    goods_id: this.skuId,
                    content: this.comment,
                    images: this.imgList,
                    recommend_id:data.id || 0
                }).then(res => {
                    if (res.code >= 0 && res.data) {
                        this.$message.success('评论成功')
                        this.comment = ''
                        this.imgList = []
                        this.imgBigList = []
						this.commentList.forEach(item=>{
							item.showReply = false;
							item.child.forEach(cItem=>{
								if(cItem.id != data.id){
									cItem.showReply = false;
								}
							});
						});
                        this.getCommnetList()
                    }else{
						this.$message.error(res.message);
					}
                })
				.catch(err=>{
					this.$message.error(err.message);
				})
            }else{
                if(!this.replyComment || !this.replyImgList){
                    this.$message.error('请输入评论内容')
                    return
                }
                getGoodsComment({
                    goods_id: this.skuId,
                    content: this.replyComment,
                    images: this.replyImgList,
                    recommend_id:data.id
                }).then(res => {
                    if (res.code >= 0 && res.data) {
                        this.$message.success('回复评论成功')
                        this.replyComment = ''
                        this.replyImgList = []
                        this.replyImgBigList = []
                        data.show = true
						this.commentList.forEach(item=>{
							item.showReply = false;
							item.child.forEach(cItem=>{
								if(cItem.id != data.id){
									cItem.showReply = false;
								}
							});
						});
                        this.selectDetail(res.data,this.replyindex)
                    }else{
						this.$message.error(res.message);
					}
                })
				.catch(err=>{
					this.$message.error(err.message);
				})
            }
		},
        // 回复弹框
        handleReply(index,parent,data = null){
			if(data){
				this.commentList.forEach(item=>{
					item.showReply = false;
					item.child.forEach(cItem=>{
						if(cItem.id != data.id){
							cItem.showReply = false;
						}
					});
				});
				this.reply = parent
				this.replyChild = data
				if(this.replyChild.showReply){
					this.replyChild.showReply = false;
					this.replyStatus = false;
				}else{
					// if(data){
					    // this.replyChild = data
					// }
					this.replyComment = '';
					this.replyImgList = [];
					this.replyImgBigList = [];
					this.replyindex = index;
					this.replyStatus = true;
					this.replyChild.showReply = true;
				}
			}else{
				this.commentList.forEach(item=>{
					item.child.forEach(cItem=>{
						cItem.showReply = false;
					});
				})
				this.commentList.forEach(item=>{
					if(item.id != parent.id){
						item.showReply = false;
					}
					// item.child.forEach(cItem=>{
					//	cItem.showReply = false;
					// });
				});
				this.reply = parent;
				this.replyChild = {};
				if(this.reply.showReply){
					this.reply.showReply = false;
					this.replyStatus = false;
				}else{
					// if(data){
					    // this.replyChild = data
					// }
					this.replyComment = '';
					this.replyImgList = [];
					this.replyImgBigList = [];
					this.replyindex = index
					this.replyStatus = true
					this.reply.showReply = true;
				}
			}
			// this.dialogReplyVisible = true
        },
        // 回复评论
        confirmReply(id){
            this.sendComment(id);
            // this.dialogReplyVisible = false;
        },
        // 获取更多评论
		getComments(data,index,content){
            if(data.replyPageCount != 0 && data.replyCurrentPage > data.replyPageCount){
                return 
            }
            data.show = true
            getMoreGoodsComment({
                recommend_id: data.id,
                page: data.replyCurrentPage,
                page_size: data.replyPageSize,
                comment_ids:content
            }).then((res) =>{
               if(res.code == 0 && res.data){
				   res.data.list.forEach((item,index)=>{
					   item.showReply = false;
					   if(item.images.length){
							item.imagesList = item.images.map(el =>{
								return this.$img(el)
							})
						}
				   })
					this.commentList[index].child.push(...res.data.list);
					data.replyPageCount = res.data.page_count;
					data.checkbox = true
					this.loading = false;
					data.replyCurrentPage ++;
               }
			   this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            })
        },
        // 查看详情
        selectDetail(id,index){
            getGoodsCommentInfo({
                recommend_id:id
            }).then((res) =>{
                if(res.code == 0 && res.data){
                    this.commentList[index].child.push(res.data)
                    this.comment_ids.push(res.data.id)
                    this.$nextTick(() => {
                        this.evaluatescroll(id)
                    })
                }
            })
        },
        // 删除评论
        handleDelete(data,index){
            this.$confirm('确定删除此条评论吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(() => {
                delGoodsComment({
                    recommend_id: data.id
                }).then(res => {
                    if (res.code == 0 && res.data) {
                        this.$message.success('删除评论成功')
                        if(!data.recommend_id){
                            this.getCommnetList()
                        }else{
                            if( this.commentList[index].child.indexOf(data)!= -1){
                                this.commentList[index].child.splice( this.commentList[index].child.indexOf(data),1)
                            }
							this.getCommnetList()
                        }
                    }else{
						this.$message.error(res.message)
					}
                })
			}).catch(() => {
			});
        },
        // 收起
        packUp(data,index){
            data.show = false
            data.checkbox = false
            data.replyCurrentPage = 1
            data.replyPageCount = 0
            this.commentList[index].child = []
        },
        offset(dom){
            let y = dom.offsetTop;
            let parent = dom.offsetParent
    
            //while判断是否有设有定位的祖先元素
            //parent.offsetParent = null表示找到的是body,则不需要进入while
            //parent.offsetParent != null表示找到的是设有定位的祖先元素
        
                while (parent.offsetParent != null) {
                y+= parent.offsetTop;
                parent = parent.offsetParent;
                }
        
                return y//{x:x,y:y}
        },
        // 滚动到容器最底部
        evaluatescroll(id){
            let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

            let height =this.offset(document.getElementById('evaluateRefs'+ id)) - 200;
            const nextSpace = 20 //每次移动距离
            let scrollTimer = setInterval(() => {
                if(scrollPosition >= height){
                    clearInterval(scrollTimer)
                }else{
                    scrollPosition += nextSpace;
                    window.scrollTo(0, scrollPosition)
                }
            }, 15)
        },
        // 报价列表
        getOfferList(){
            getGoodsTender({
                goods_id: this.skuId,
                page:this.offerCurrentPage,
                page_size: this.offerPageSize
            }).then(res => {
                if (res.code == 0 && res.data) {
                    this.offerList = res.data.list;
					this.offerList.forEach(item=>{
						if(item.images){
							item.imagesList = item.images.split(',').map(el =>{
								return this.$img(el)
							})
						}
					})
                    this.offerTotal = res.data.count;
                }
				this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },
        handleOfferPageSizeChange(size) {
            this.offerPageSize = size;
			this.loading = true;
            this.getOfferList();
        },
        handleOfferCurrentPageChange(page) {
            this.offerCurrentPage = page;
			this.loading = true;
            this.getOfferList();
        },
        changeOffer(){
			this.$refs.offerRefs.offerData.offercode = '';
			this.$refs.offerRefs.offerData.offerPrice = '';
			this.$refs.offerRefs.offerData.offerImgList = [];
			this.$refs.offerRefs.offerData.offerImgBigList = [];
			this.$refs.offerRefs.offerData.offercontent = '';
			
            this.$refs.offerRefs.dialogOfferVisible = true
        },
        handleOfferList(){
            this.offerCurrentPage = 1
            this.getOfferList()
        },
        // 编辑报价
        offerEdit(data){
            let  offer = Object.assign({},data)
            this.$refs.offerRefs.offerData.tender_id = offer.id
            this.$refs.offerRefs.offerData.offercode = offer.goods_code
            this.$refs.offerRefs.offerData.offerPrice = offer.price
            this.$refs.offerRefs.offerData.offercontent = offer.content
            this.$refs.offerRefs.offerData.offerImgList = offer.images.split(',')
            this.$refs.offerRefs.offerData.offerImgBigList = offer.images.split(',')
            this.$refs.offerRefs.dialogOfferVisible = true
            this.$refs.offerRefs.status = 1
        },
        // 删除投标
        offerDelete(data){
            // this.$confirm('确定要删除此条投标吗?', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            //   }).then(() => {
                delGoodsOffer({
                    tender_id: data.id
                }).then(res => {
                    if (res.code == 0 && res.data) {
                        this.$message.success('删除成功')
                        this.getOfferList()
                    }else{
						this.$message.error(res.message)
					}
                })
              // }).catch(() => {
                       
              // });
            
        }
    }
}
